import { trackingKaminoURL } from '../../configuration/Configuration.js';
import rejectNonOkResponse from '../../scripts/api/rejectNonOkResponse.js';

export default async (urlTracking, jsonQueryParams) => {
  const res = (
    await rejectNonOkResponse(
      fetch(trackingKaminoURL, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          urlTracking,
          jsonQueryParams: JSON.stringify(jsonQueryParams),
        }),
      })
    )
  ).text();
  return res;
};
