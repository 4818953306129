import { Liquid } from 'liquidjs';
import _plugin0 from "./configuration/liquid-plugin/index.js";

// TODO create a external package: import VolatilFileSystem from "@fnacdarty/liquid-loader/volatil-fs.js"
class VolatilFileSystem {
  #entries;
  constructor(entries = new Map()){
    this.#entries = entries;
  }
  readFileSync(filepath) {
    return this.#entries.get(filepath);
  }
  readFile(filepath) {
    return Promise.resolve(this.readFileSync(filepath));
  }
  existsSync(filepath) {
    return this.#entries.has(filepath);
  }
  exists(filepath) {
    return Promise.resolve(this.existsSync(filepath));
  }
  resolve(root, filepath, ext) {
    return root + '/' + (filepath.endsWith(ext) ? filepath : filepath + ext);
  }
}

const templates = new Map();

export const registerTemplate = (filepath, content) => templates.set(filepath, content);

const liquid = new Liquid({
  fs: new VolatilFileSystem(templates),
  cache: Number.POSITIVE_INFINITY, // no limit
  root: ["../www","."],
  extname: ".liquid",
  relativeReference: false, // disable it or VolatilFileSystem need to implement dirname and sep methods. See https://github.com/harttle/liquidjs/blob/e401a30972c70be501d91fd999dc305ec67a15a2/src/liquid-options.ts#L183-L186
});
for (const plugin of [_plugin0]) {
  liquid.plugin(plugin);
}

export default liquid;