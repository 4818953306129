import componentRegistry from '../registry.js';
import rejectNonOkResponse from '../../scripts/api/rejectNonOkResponse.js';

const cache = {};

const fetchWithCache = async (href) => {
  if (cache[href]) {
    return cache[href];
  } else {
    const res = await (
      await rejectNonOkResponse(
        fetch(href, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        })
      )
    ).text();
    cache[href] = res;
    return res;
  }
};

const getContent = async (href, names) => {
  const json = JSON.parse(await fetchWithCache(href));

  for (const name of names) {
    if (json[name]) return json[name];
  }
  return '';
};

export default class LazyHtmlCacheable {
  constructor({ el }) {
    this.el = el;
    this.load();
  }

  async load() {
    const { href, format } = this.el.dataset;
    const formats = format.split(',').map((el) => el.trim());
    const content = await getContent(href, formats);
    this.el.innerHTML = content;
  }
}

componentRegistry.define('js-lazy-html-cacheable', LazyHtmlCacheable);
