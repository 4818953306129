import componentRegistry from '../../registry.js';
import postTracking from '../tracking.js';
import { isMobile } from '../../../configuration/Configuration.js';
// Carousels
import NCarousel from '../../../components/n-carousel/index.js';
import CarouselScrollSnap from '../../../scripts/common/components/CarouselScrollSnap.js';

import './index.less';

export default class Kamino {
  #links;
  trackingImp;
  trackingView;
  trackingLoad;
  trackingClick;
  trackingVideo;
  trackingPview;

  constructor({ el }) {
    this.el = el;
    this.#initCarousel();
    this.#links = el.querySelectorAll('.minifa');

    this.trackingImp = el.dataset.trackingImp;
    this.trackingView = el.dataset.trackingView;
    this.trackingLoad = el.dataset.trackingLoad;
    this.trackingClick = el.dataset.trackingClick;
    this.trackingVideo = el.dataset.trackingVideo;
    this.trackingPview = el.dataset.trackingPview;

    // fire Format inserted on the page tracking
    postTracking(this.trackingImp);

    this.#links.forEach((link) => {
      link.addEventListener('click', this.#onClickLink.bind(this));
    });
    el.querySelectorAll('.js-ProductBuy').addEventListener('submit', this.#onAddToCart.bind(this));
  }

  #initCarousel() {
    var carousel = this.el.querySelector('.js-NCarousel');
    if (isMobile) {
      new CarouselScrollSnap({ carousel });
    } else {
      new NCarousel({ carousel });
    }
  }

  #onClickLink(e) {
    // fire on click product info tracking event
    if (e.target.closest('js-ProductBuy-add') != null) {
      // fire on click product info tracking event
      postTracking(this.trackingClick, { clickZone: 'product', act: 'product-page', st: this.el.dataset.prid });
    }
  }

  #onAddToCart() {
    // fire on click add to cart tracking event
    postTracking(this.trackingClick, { clickZone: 'product', act: 'add-to-cart', st: this.el.dataset.prid });
  }
}

componentRegistry.define('js-kamino-sword', Kamino);
