import componentRegistry from '../../registry.js';
import './index.less';

// function getTranslateXY(element) {
//   const style = window.getComputedStyle(element)
//   const matrix = new DOMMatrixReadOnly(style.transform)
//   return {
//       translateX: matrix.m41,
//       translateY: matrix.m42
//   }
// }

export default class KaminoImpulse {
  #isExpand;
  #impulseExpand;
  #video;
  #popin;
  #popinVideo;
  #productList;
  trackingImp;
  trackingView;
  trackingLoad;
  trackingClick;
  trackingVideo;
  trackingPview;

  constructor({ el }) {
    this.el = el;
    this.#impulseExpand = el.querySelector('.js-kamino-expand');
    this.#popin = el.querySelector('.js-GetPopin');
    this.#video = el.querySelector('video');
    this.#productList = el.querySelector('.f-kamino-products');
    this.#popinVideo = el.querySelector('#js-kamino-impulse-popin video');
    this.#isExpand = true;
    this.trackingImp = el.dataset.trackingImp;
    this.trackingView = el.dataset.trackingView;
    this.trackingLoad = el.dataset.trackingLoad;
    this.trackingClick = el.dataset.trackingClick;
    this.trackingVideo = el.dataset.trackingVideo;
    this.trackingPview = el.dataset.trackingPview;

    //fire Format inserted on the page tracking
    // postTracking(this.trackingImp);

    this.#popin?.addEventListener('popin:open', () => {
      console.log('is been open');
      this.#popinVideo.play();
      this.#video.pause();
    });
    this.#popin?.addEventListener('popin:close', () => {
      console.log('is been close');
      this.#popinVideo.pause();
      this.#video.play();
    });
    this.#video.play();

    this.#impulseExpand?.addEventListener('click', this.#onImpulseExpand.bind(this));

    var isBusy = false;
    const childrenCount = this.#productList.children.length;
    const blockSize = 132.22;
    var index = 0;
    this.#productList.addEventListener('wheel', (e) => {
      if (isBusy) return;
      isBusy = true;
      setTimeout(() => {
        isBusy = false;
      }, 1000);

      if (e.deltaY < 0) {
        console.log('up');
        const cln = this.#productList.children[childrenCount - 1].cloneNode(true);
        this.#productList.prepend(cln);
        this.#productList.children[childrenCount].remove();
        // this.#productList.style.transform = `translateY(${getTranslateXY(this.#productList).translateY + 132.22 }px)`
      } else if (e.deltaY > 0) {
        console.log('down');
        const cln = this.#productList.children[0].cloneNode(true);
        this.#productList.append(cln);
        this.#productList.children[0].remove();
        // this.#productList.style.transform = `translateY(${getTranslateXY(this.#productList).translateY - 132.22 }px)`
      }
    });
  }

  #onImpulseExpand() {
    if (this.#isExpand) {
      this.el.style.bottom = '-120px';
      this.#impulseExpand.style.transform = 'rotate(180deg)';
      this.#isExpand = false;
      this.#video.pause();
    } else {
      this.el.style.bottom = '0px';
      this.#impulseExpand.style.transform = 'rotate(0deg)';
      this.#isExpand = true;
      this.#video.play();
    }
  }
}

componentRegistry.define('js-kamino-impulse', KaminoImpulse);
