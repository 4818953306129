import componentRegistry from '../registry.js';
import IntersectionObserver from '../../scripts/polyfills/web.naive-intersectionobserver.js';
import postTracking from './tracking.js';
import iconHTML from '../../helpers/html/icon.js';
import mutedIcon from '@fnacdarty/fnac-icons/svg/icon_i_311_volume_muted.svg?svgref';
import unmuteIcon from '@fnacdarty/fnac-icons/svg/icon_i_309_volume_high.svg?svgref';
import playIcon from './assets/play.svg';
import pauseIcon from './assets/pause.svg';
import './video.less';

export default class KaminoVideo {
  #video;
  #soundIcon;
  #intersectionObserver;
  #notifierIcon;
  #notifier;
  trackingClick;
  trackingVideo;

  constructor({ el }) {
    this.el = el;
    this.#video = el.querySelector('video');
    this.#soundIcon = el.querySelector('.js-sound-icon');
    this.#notifier = el.querySelector('.js-notifier-icon');
    this.#notifierIcon = el.querySelector('.js-notifier-icon img');
    this.trackingClick = el.closest('[data-tracking-click]')?.dataset.trackingClick;
    this.trackingVideo = el.closest('[data-tracking-video]')?.dataset.trackingVideo;

    this.#video?.addEventListener('timeupdate', this.#onVideoProgress.bind(this));
    this.#soundIcon?.addEventListener('click', this.#onClickMuteButton.bind(this));
    this.#video?.addEventListener('click', this.#onClickVideo.bind(this));
    this.#video?.addEventListener('play', this.#onPlayVideo.bind(this));
    this.#video?.addEventListener('pause', this.#onPauseVideo.bind(this));

    this.#intersectionObserver = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        var progressPercentage = (this.#video.currentTime / this.#video.duration) * 100;
        progressPercentage = isNaN(progressPercentage) ? 0 : progressPercentage;

        if (isIntersecting) {
          if (this.#video) {
            this.#video.play();
            // fire Scroll-in to display less than 50% of the video tracking (once)
            postTracking(this.trackingVideo, {
              act: progressPercentage == 0 ? 'step' : 'resume',
              st: progressPercentage,
            });
          }
        } else {
          if (this.#video) {
            this.#video.pause();
            //fire Scroll-out to display less than 50% of the video tracking (once)
            postTracking(this.trackingVideo, { act: 'pause', st: progressPercentage });
          }
        }
      },
      { threshold: 0.5 }
    );
    if (this.#video) this.#intersectionObserver.observe(this.#video);
  }

  #onPlayVideo() {
    this.#notifierIcon.src = playIcon;
    this.#notifier.dataset.hidden = false;
    setTimeout(() => {
      this.#notifier.dataset.hidden = true;
    }, 750);
  }

  #onPauseVideo() {
    this.#notifierIcon.src = pauseIcon;
    this.#notifier.dataset.hidden = false;
    setTimeout(() => {
      this.#notifier.dataset.hidden = true;
    }, 750);
  }

  #onClickVideo() {
    var progressPercentage = (this.#video.currentTime / this.#video.duration) * 100;
    progressPercentage = isNaN(progressPercentage) ? 0 : progressPercentage;

    //fire play or pause tracking event
    postTracking(this.trackingClick, {
      click_zone: 'video',
      act: this.#video.paused ? 'play' : 'pause',
      st: progressPercentage,
    });

    this.#video.paused ? this.#video.play() : this.#video.pause();
  }

  #onClickMuteButton() {
    if (this.#video.muted) {
      this.#soundIcon.innerHTML = iconHTML(unmuteIcon);
    } else {
      this.#soundIcon.innerHTML = iconHTML(mutedIcon);
    }

    //fire mute or unmuted tracking
    postTracking(this.trackingClick, { click_zone: 'video', act: this.#video.muted ? 'unmute' : 'mute' });

    this.#video.muted = !this.#video.muted;
  }

  progress25 = false;
  progress50 = false;
  progress75 = false;
  progress100 = false;
  #onVideoProgress() {
    const progressBar = this.el.querySelector('.f-kamino-video-progress--filled');
    var progressPercentage = (this.#video.currentTime / this.#video.duration) * 100;
    progressPercentage = isNaN(progressPercentage) ? 0 : progressPercentage;

    progressBar.style.flexBasis = `${progressPercentage}%`;

    // fire completion trackers (25, 50, 75 & 100% only once)
    if (progressPercentage >= 25 && !this.progress25) {
      postTracking(this.trackingVideo, { act: 'step', st: '25' });
      this.progress25 = true;
    } else if (progressPercentage >= 50 && !this.progress50) {
      postTracking(this.trackingVideo, { act: 'step', st: '50' });
      this.progress50 = true;
    } else if (progressPercentage >= 75 && !this.progress75) {
      postTracking(this.trackingVideo, { act: 'step', st: '75' });
      this.progress75 = true;
    } else if (progressPercentage > 99 && !this.progress100) {
      postTracking(this.trackingVideo, { act: 'step', st: '100' });
      this.progress100 = true;
      this.#video?.removeEventListener('timeupdate', this.#onVideoProgress);
    }
  }
}

componentRegistry.define('js-kamino-video', KaminoVideo);
