import componentRegistry from '../../registry.js';
import postTracking from '../tracking.js';
import './index.less';

export default class Kamino {
  #product;
  #image;
  #prid;
  #intersectionObserver;
  trackingImp;
  trackingClick;

  constructor({ el }) {
    this.el = el;
    this.#product = el.querySelector('.js-kamino-product');
    this.#image = el.querySelector('.js-kamino-image');
    this.#prid = `${this.el.dataset.catalog}-${this.el.dataset.prid}`;
    this.trackingImp = el.dataset.trackingImp;
    this.trackingClick = el.dataset.trackingClick;
    this.trackingView = el.dataset.trackingView;
    this.trackingPview = el.dataset.trackingPview;

    //fire Format inserted on the page tracking
    postTracking(this.trackingImp);

    this.#product.addEventListener('click', this.#onClickProduct.bind(this));
    this.#image?.addEventListener('click', this.#onClickImage.bind(this));
    el.querySelector('.js-ProductBuy').addEventListener('submit', this.#onAddToCart.bind(this));

    var trackingViewOccurence = [false, false, false];

    const visibleTimeRequired = 2; // seconds
    this.#intersectionObserver = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        let visibleTime = 0; // track the amount of time the element is visible
        let visibilityTimer;
        if (isIntersecting) {
          if (trackingViewOccurence[visibleTime] === false) {
            postTracking(this.trackingView, { st: visibleTime });
            trackingViewOccurence[visibleTime] = true;
          }
          // Start tracking visible time
          visibilityTimer = setInterval(() => {
            visibleTime += 1;
            if (trackingViewOccurence[visibleTime] === false) postTracking(this.trackingPview, { st: visibleTime }); //Post tracking every seconds
            trackingViewOccurence[visibleTime] = true;
            if (visibleTime >= visibleTimeRequired) {
              // Clear the interval after the required time is met
              clearInterval(visibilityTimer);
              //remove intersectionObserver once all the visibility trackers has been called
              this.#intersectionObserver.disconnect();
            }
          }, 1000);
        } else {
          clearInterval(visibilityTimer);
          visibleTime = 0;
        }
      },
      { threshold: 0.5 }
    );
    this.#intersectionObserver.observe(el);
  }

  #onClickProduct(e) {
    if (e.target.closest('.js-ProductBuy-add') == null) {
      //fire on click product info tracking event
      postTracking(this.trackingClick, { click_zone: 'product', act: 'product-page', product_id: this.#prid });
    }
  }

  #onClickImage(e) {
    //fire on click product info tracking event
    postTracking(this.trackingClick, { click_zone: 'image', act: 'redirect' });
  }

  #onAddToCart() {
    //fire on click add to cart tracking event
    postTracking(this.trackingClick, { click_zone: 'product', act: 'add-to-cart', product_id: this.#prid });
  }
}

componentRegistry.define('js-kamino-squad', Kamino);
